<template>
  <div
    class="modal fade"
    id="modalAccountEstimated"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Barang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Kode </label>
              <input
                type="text"
                class="form-control"
                id="inlineFormInputGroupUsername"
                placeholder="Masukkan Kode"
                value="Kas Kecil Kantor Jogja"
              />
            </div>

            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Nama Barang </label>
              <input
                type="text"
                class="form-control"
                id="inlineFormInputGroupUsername"
                placeholder="Masukkan Nama Barang"
                value="Iphone 14 Super Max 256GB"
              />
            </div>

            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Kuantitas </label>
              <div class="row">
                <div class="col-6" style="padding-right: 0px">
                  <input
                    type="text"
                    class="form-control text-right"
                    id="inlineFormInputGroupUsername"
                    placeholder="Masukkan Kuantitas"
                    value="2"
                  />
                </div>
                <div class="col-6">
                  <Select2
                    v-model="unit"
                    :options="OptionUnit"
                    placeholder="Satuan"
                    :settings="{ settingOption: value, settingOption: value }"
                    @change="myChangeEvent($event)"
                    @select="mySelectEvent($event)"
                  />
                </div>
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label for="inputAddress2" class="form-label">Gudang </label>
              <Select2
                style="width: 100%"
                v-model="werhouse"
                :options="OptionWerhouse"
                placeholder="Pilih Gudang"
                :settings="{ settingOption: value, settingOption: value }"
                @change="myChangeEvent($event)"
                @select="mySelectEvent($event)"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-save">Simpan</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="">
            <h4 class="title">Tambah Hasil Opname</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/data-stock"
                    >Tambah Hasil Opname
                  </router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Edit</li>
              </ol>
            </nav>
          </div>
          <div>
            <button class="btn btn-save">Simpan</button>
          </div>
        </div>

        <div class="card-table info-detail">
          <div class="row mt-3">
            <div class="col-12">
              <div class="title">Tambah Hasil Opname</div>
              <div class="sub-title">Tambah informasi yang diperlukan</div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="inputAddress2">Nomor :</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputAddress2"
                  placeholder="Masukkan Nomor"
                />
              </div>

              <div class="form-group">
                <label for="inputAddress2">Tanggal :</label>
                <Datepicker
                  :autoApply="true"
                  v-model="dateOpname"
                  :format="format"
                ></Datepicker>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group">
                <label class="" for="inlineFormInputGroupUsername"
                  >Nomor Perintah :</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="inputAddress2"
                  placeholder="Masukkan Nomor Perintah"
                />
              </div>

              <div class="form-group">
                <label class="" for="inlineFormInputGroupUsername"
                  >Keterangan :</label
                >
                <textarea
                  type="text"
                  class="form-control"
                  id="inputAddress2"
                  placeholder="Masukkan Keterangan"
                />
              </div>
            </div>
          </div>

          <div class="row align-items-center mt-5">
            <div class="col-12">
              <div class="title">Rincian Barang</div>
              <div class="sub-title">
                Rincian dari rincian barang yang diperlukan
              </div>
            </div>
          </div>
          <hr />

          <div class="search">
            <div class="form-group" style="width: 400px">
              <Select2
                v-model="AccountEstimated"
                :options="OptionAccountEstimated"
                placeholder="Pilih Barang."
                :settings="{ templateResult: formatState }"
                @change="myChangeEvent($event)"
                @select="mySelectFormState($event)"
              />
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered table-striped" id="tablePOb">
              <thead>
                <tr>
                  <th style="width: 15%">Nama Barang</th>
                  <th style="width: 20%">Kode</th>
                  <th style="width: 20%">Qty</th>
                  <th style="width: 8%">Satuan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Iphone 14 Super Max 256GB - Grey</td>
                  <td>9900012</td>
                  <td>10</td>
                  <td>pcs</td>
                </tr>
                <tr>
                  <td>Iphone 14 Super Max 256GB - Grey</td>
                  <td>9900012</td>
                  <td>10</td>
                  <td>pcs</td>
                </tr>
                <tr>
                  <td>Iphone 14 Super Max 256GB - Grey</td>
                  <td>9900012</td>
                  <td>10</td>
                  <td>pcs</td>
                </tr>
                <tr>
                  <td>Iphone 14 Super Max 256GB - Grey</td>
                  <td>9900012</td>
                  <td>10</td>
                  <td>pcs</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  data() {
    return {
      dateOpname: null,
      OptionStatus: ["Akitf", "Nonaktif"],
      OptionAccountEstimated: [
        {
          id: 1,
          text: "Iphone 14 Super Max",
          no: "9900012",
          type: "9900012",
        },

        {
          id: 2,
          text: "Iphone 13 Pro Max",
          no: "9900012",
          type: "9900012",
        },
      ],
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    $("#tablePOb").DataTable({
      info: false,
    });

    this.tooltip();
  },

  methods: {
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var $state = $(
        "<div>" +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({
        id,
        text,
      });
    },

    mySelectFormState({ id, text, no, type }) {
      $("#modalAccountEstimated").modal("show");
      console.log({
        id,
        text,
        no,
        type,
      });
    },
  },
};
</script>

<style scoped src="../../../../../assets/css/style.css"></style>

<style scoped src="../../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
